@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons";
// import font
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Kalam:wght@300;400;700&display=swap');

// Light mode variables
$light-bg: #f8f9fa;
$light-card-bg: #ffffff;
$light-text: #333333;
$light-secondary-text: #6c757d;
$light-border: #e0e0e0;
$light-primary: #007bff;
$light-danger: #dc3545;

// Dark mode variables
$dark-bg: #121212;
$dark-card-bg: #1e1e1e;
$dark-text: #e0e0e0;
$dark-secondary-text: #adb5bd;
$dark-border: #2a2a2a;
$dark-primary: #4dabf7;
$dark-danger: #ff6b6b;

// Common variables
$border-radius: 12px;
$box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

.app {
  padding-bottom: 80px;

  @media (min-width: 768px) {
    padding-bottom: 0;
  }

  @media (max-width: 767px) {
    padding-bottom: 50px; // Reduce this value further
  }

  & > .container-sm {
    padding-top: 20px;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid black;
    padding-bottom: 10px;

    h1 {
      font-family: "Kalam", cursive;
      font-weight: 700;
      margin: 0;
    }

    .btn-outline-secondary {
      padding: 0.25rem 0.5rem;
      font-size: 1.25rem;
    }
  }
}

.app-section-title {
  font-size: 1em;
  font-weight: 400;
  color: #999999;
  margin-top: 12px;
  text-align: center;
}

.card {
  margin-bottom: 1.5rem;
  position: relative;  // Add this line

  .card-header {
    cursor: pointer;
  }

  .delete-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 10;
    background: none;
    border: none;
    color: #6c757d;  // Bootstrap's text-secondary color
    padding: 0;
    font-size: 1.25rem;
    line-height: 1;
    opacity: 0.5;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }
}

.meal-category-section {
  margin-bottom: 1rem;  // Reduce this from 1.5rem to 1rem
}

.meal-category-title {
  font-family: "Kalam", cursive;
  font-weight: 700;
  font-size: 1.75rem;
  color: var(--bs-body-color);
  margin-bottom: 0.75rem;  // Reduce this from 1.5rem to 0.75rem
  display: flex;
  align-items: center;
  opacity: 0.8;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }

  i {
    margin-right: 0.75rem;
    font-size: 1.5rem;
    color: var(--bs-primary);
  }
}

.meal-items {
  gap: 0rem;
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}

.consumption-history-item {
  background-color: var(--bs-body-bg);
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;  // Add this line to ensure proper positioning of the delete button
  margin-bottom: 1rem;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  }

  .card-header {
    background-color: transparent;
    border-bottom: none;
    padding: 1.5rem;
  }

  .item-details {
    display: flex;
    align-items: center;
  }

  .item-image {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 12px;
    margin-right: 1rem;
  }

  .item-info {
    flex-grow: 1;
  }

  .item-title {
    font-family: "Kalam", cursive;
    font-weight: 700;
    font-size: 1.1rem;
    margin-bottom: 0.25rem;
    color: var(--bs-body-color);
  }

  .item-meta {
    font-size: 0.875rem;
    color: var(--bs-secondary);
  }

  .badge {
    font-size: 0.75rem;
    padding: 0.35em 0.65em;
    border-radius: 20px;
    font-weight: 500;
  }

  .delete-btn {
    position: absolute;
    top: -20px;  // Change this from 0.5rem to 0
    right: 8px;  // Change this from 0.5rem to 0
    z-index: 10;
    background: none;
    border: none;
    color: #c50b0b;
    padding: 0.5rem;
    font-size: 1.25rem;
    line-height: 1;
    opacity: 0.5;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }

  .allergy-alert-btn {
    position: absolute;
    top: 0.5rem;
    right: 2.5rem;  // Adjust this value to position it next to the delete button
    z-index: 10;
    background: none;
    border: none;
    color: #ffc107;  // Warning color
    padding: 0.5rem;
    font-size: 1.25rem;
    line-height: 1;
    opacity: 0.8;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }
}

// Dark mode adjustments
[data-bs-theme="dark"] {
  .consumption-history-item {
    background-color: rgba(255, 255, 255, 0.05);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);

    &:hover {
      box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
    }

    .delete-btn {
      color: #ff6b6b;  // A lighter red for better visibility in dark mode
    }

    .allergy-alert-btn {
      color: #ffd54f;  // A lighter yellow for better visibility in dark mode
    }
  }

  .meal-category-title {
    color: var(--bs-light);
  }

  .item-title {
    color: var(--bs-light);
  }

  .item-meta {
    color: var(--bs-secondary);
  }
}

// Animations
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.meal-category-section {
  animation: fadeIn 0.5s ease-out forwards;
}

.consumption-history-item {
  animation: fadeIn 0.5s ease-out forwards;
  animation-delay: calc(var(--animation-order) * 0.1s);
  opacity: 0;
}

// Ensure smooth transitions for theme changes
body, .consumption-history-item, .card-header {
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.progress-container {
  margin-bottom: 1rem;

  .progress-label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25rem;
    font-size: 0.9rem;

    .total-value {
      font-weight: 600;
    }

    .rdi-value {
      color: var(--bs-secondary);
    }
  }

  .progress {
    height: 1.5rem;
  }
}

.nutrition-summary {
  .card {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    border: none;
    border-radius: 12px;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }
  }

  .card-title {
    color: #333;
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .circular-progress {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: conic-gradient(#3e98c7 var(--percentage), #e9ecef 0deg);
    display: flex;
    align-items: center;
    justify-content: center;

    .inner {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;

      .number {
        font-size: 1.2rem;
        font-weight: bold;
        color: #3e98c7;
      }
    }
  }

  .nutrient-value {
    font-size: 1.1rem;
    font-weight: 600;
    color: #333;
  }

  .nutrient-rdi {
    font-size: 0.9rem;
    color: #6c757d;
  }

  .nutrient-difference {
    font-size: 0.9rem;
    font-weight: 500;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    text-align: center;
    margin-top: 0.5rem;

    &.over-rdi {
      background-color: #ffeeba;
      color: #856404;
    }

    &.under-rdi {
      background-color: #d4edda;
      color: #155724;
    }
  }
}

.recipe-container {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  margin-top: 20px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.recipe-title {
  font-size: 1.2rem;
  color: #333;
}

.recipe-toggle {
  font-size: 0.9rem;
}

.recipe-content {
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow-y: auto;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out, background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  padding: 15px;
  border-radius: 4px;
}

.recipe-content.collapsed {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.recipe-section-title {
  color: #007bff;
  margin-bottom: 10px;
}

.recipe-line {
  font-size: 0.9rem;
  line-height: 1.5;
}

.recipe-download {
  display: block;
  margin-left: auto;
  margin-right: 0;
}

.deletable-item {
  position: relative;

  .delete-btn {
    position: absolute;
    top: -20px;
    right: 10px;
    z-index: 10;
    background: none;
    border: none;
    color: #c50b0b;
    padding: 0.5rem;
    font-size: 1.25rem;
    line-height: 1;
    opacity: 0.5;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }
}

body {
  transition: background-color 0.3s ease, color 0.3s ease;
}

[data-bs-theme="dark"] {
  --bs-body-color: #e0e0e0;
  --bs-body-bg: #121212;  // Darker background
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;

  .card {
    background-color: #1e1e1e;  // Darker card background
    border-color: #333;
  }

  .card-header {
    background-color: #252525;  // Slightly lighter than card for contrast
    border-bottom-color: #333;
  }

  .list-group-item {
    background-color: transparent;
    border: none;  // Ensure no borders in dark mode as well
    color: #e0e0e0;
  }

  .text-muted {
    color: #adb5bd !important;
  }

  .nav-tabs .nav-link {
    color: #e0e0e0;
    &.active {
      background-color: #1e1e1e;  // Match card background
      border-color: #333 #333 #1e1e1e;
      color: #fff;
    }
  }

  .btn-outline-primary {
    color: #0d6efd;
    border-color: #0d6efd;
    &:hover {
      background-color: #0d6efd;
      color: #fff;
    }
  }

  .btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d;
    &:hover {
      background-color: #6c757d;
      color: #fff;
    }
  }

  .btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545;
    &:hover {
        background-color: #dc3545;
        color: #fff;
      }
  }

  .bg-light {
    background-color: #252525 !important;  // Slightly lighter than body background
  }

  .border-bottom {
    border-bottom-color: #333 !important;
  }

  // Additional styles for better contrast
  .form-control, .form-select {
    background-color: #2a2a2a;
    border-color: #444;
    color: #e0e0e0;
  }

  .btn-primary {
    background-color: #0d6efd;
    border-color: #0d6efd;
    &:hover {
      background-color: #0b5ed7;
      border-color: #0a58ca;
    }
  }

  // Adjust progress bar background
  .progress {
    background-color: #2a2a2a;
  }

  .recipe-container {
    background-color: #2c3034;
    border-color: #495057;
  }

  .recipe-content {
    background-color: #212529;
    border-color: #495057;
    color: #e0e0e0;
  }

  .recipe-section-title {
    color: #0d6efd;
  }

  .recipe-line {
    color: #e0e0e0;
  }
}

// Ensure smooth transitions
body, .card, .list-group-item, .form-control, .form-select, .btn {
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
}

.nav-tabs {
  border-bottom: 2px solid var(--bs-border-color);
  margin-bottom: 1rem; // Reduce this value
  margin-top: -1.5rem; // Add this line to pull the tabs up slightly

  .nav-item {
    margin-bottom: -2px;

    .nav-link {
      border: none;
      color: var(--bs-body-color);
      font-weight: 500;
      padding: 0.75rem 1rem;
      transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: transparent;
        transition: background-color 0.3s ease;
      }

      &:hover {
        background-color: rgba(var(--bs-primary-rgb), 0.1);
        color: var(--bs-primary);
      }

      &.active {
        color: var(--bs-primary);
        background-color: transparent;
        border: none;

        &::after {
          background-color: var(--bs-primary);
        }
      }

      i {
        margin-right: 0.5rem;
      }
    }
  }
}

[data-bs-theme="dark"] {
  .nav-tabs {
    border-bottom-color: var(--bs-border-color);

    .nav-item {
      .nav-link {
        color: var(--bs-body-color);

        &:hover {
          background-color: rgba(var(--bs-primary-rgb), 0.2);
          color: var(--bs-primary);
        }

        &.active {
          color: var(--bs-primary);
          background-color: transparent;

          &::after {
            background-color: var(--bs-primary);
          }
        }
      }
    }
  }
}

.alert-danger {
  background-color: #ffe6e6;
  border-color: #ffcccc;
  color: #cc0000;

  .alert-heading {
    color: #990000;
  }

  hr {
    border-top-color: #ffb3b3;
  }

  .btn-close {
    color: #cc0000;
  }
}

[data-bs-theme="dark"] {
  .alert-danger {
    background-color: #330000;
    border-color: #660000;
    color: #ff9999;

    .alert-heading {
      color: #ff6666;
    }

    hr {
      border-top-color: #800000;
    }

    .btn-close {
      color: #ff9999;
    }
  }
}

// Modify the fixed-bottom div styles
.fixed-bottom.bg-light {
  @media (max-width: 767px) {
    padding-top: 0 !important; // Remove all padding
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    height: 70px; // Set a fixed height
    display: flex;
    align-items: center;
  }

  .btn {
    @media (max-width: 767px) {
      height: 100%; // Make the button fill the entire height
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px; // Remove border radius
      font-size: 1.2rem;
      font-family: "Kalam", cursive; // Add this line to change the font
      font-weight: 700; // Add this line to make the font bold
    }
  }
}

.saved-recipes {
  max-width: 1200px;
  margin: 0 auto;

  .recipe-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1.5rem;
  }

  .recipe-card {
    background-color: var(--bs-light);
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
    }

    .recipe-image {
      height: 200px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;
      }

      .placeholder-image {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f0f0f0;
        color: #999;
        font-size: 3rem;
      }
    }

    &:hover .recipe-image img {
      transform: scale(1.05);
    }

    .recipe-content {
      padding: 1.25rem;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }

    .recipe-title {
      font-size: 1.25rem;
      margin-bottom: 0.75rem;
      font-weight: 600;
      line-height: 1.3;
      color: var(--bs-body-color);
    }

    .recipe-details {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      font-size: 0.9rem;
      color: var(--bs-secondary);
    }

    .recipe-info {
      display: flex;
      justify-content: space-between;
    }

    .recipe-cuisine,
    .recipe-difficulty {
      display: inline-block;
      padding: 0.25rem 0.5rem;
      border-radius: 20px;
      background-color: rgba(var(--bs-primary-rgb), 0.1);
      color: var(--bs-primary);
      font-size: 0.8rem;
      font-weight: 500;
    }

    .recipe-actions {
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
      display: flex;
      gap: 0.5rem;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &:hover .recipe-actions {
      opacity: 1;
    }

    .btn-icon {
      width: 36px;
      height: 36px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: var(--bs-light);
      border: none;
      font-size: 1rem;
      transition: background-color 0.3s ease, color 0.3s ease;

      &:hover {
        background-color: var(--bs-primary);
        color: var(--bs-light);
      }
    }
  }
}

// Dark mode adjustments
[data-bs-theme="dark"] {
  .recipe-card {
    background-color: var(--bs-dark);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);

    &:hover {
      box-shadow: 0 8px 25px rgba(0, 0, 0, 0.4);
    }

    .recipe-image .placeholder-image {
      background-color: #2a2a2a;
      color: #555;
    }

    .recipe-title {
      color: var(--bs-light);
    }

    .recipe-details {
      color: var(--bs-secondary);
    }

    .recipe-cuisine,
    .recipe-difficulty {
      background-color: rgba(var(--bs-primary-rgb), 0.2);
    }

    .btn-icon {
      background-color: var(--bs-dark);
      color: var(--bs-light);

      &:hover {
        background-color: var(--bs-primary);
      }
    }
  }
}

// Add this new rule for all headings
h1, h2, h3, h4, h5, h6 {
  font-family: "Kalam", cursive;
  font-weight: 700;
}

.nutrition-dashboard {
  background-color: var(--bs-light);
  border-radius: 20px;
  padding: 1rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  transition: all 0.3s ease;

  &.collapsed {
    padding-bottom: 0.25rem;
  }

  &.expanded {
    padding-bottom: 1rem;
  }

  .total-calories-display {
    cursor: pointer;
    position: relative;

    .expand-icon {
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      background-color: var(--bs-light);
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;

      i {
        transition: transform 0.3s ease;
      }
    }
  }

  &.expanded .total-calories-display .expand-icon i {
    transform: rotate(180deg);
  }

  .nutrient-summary {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: all 0.3s ease;
  }

  &.expanded .nutrient-summary {
    max-height: 1000px;
    opacity: 1;
    margin-top: 1rem;
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .nutrition-dashboard {
    padding: 1rem;

    .main-calorie-display {
      flex-direction: column;
      text-align: center;

      .calorie-circle {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }

    .nutrient-grid {
      grid-template-columns: 1fr;
    }
  }

  .nutrition-dashboard {
    padding-bottom: 4rem; // Even more padding on smaller screens
    margin-bottom: 3rem; // More margin on smaller screens
  }
}

[data-bs-theme="dark"] {
  // ... (existing dark mode styles)

  .nutrition-dashboard {
    background-color: #181818;
    border: 1px solid var(--bs-dark); // this is the dark mode Nutrition summary boarder

    .total-calories-display .expand-icon {
      background-color: var(--bs-dark);
    }
  }
}

// Ensure smooth transitions for theme changes
.nutrition-dashboard,
.nutrition-dashboard .calorie-circle,
.nutrition-dashboard .calorie-inner,
.nutrition-dashboard .nutrient-item,
.nutrition-dashboard .nutrient-bar {
  transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
}

.total-calories-display {
  background-color: var(--bs-light);
  border-radius: 15px;
  padding: 1rem;
  display: flex;
  align-items: center;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;  // Add this line
  margin-bottom: 1.5rem;
  position: relative;

  .calories-navigation {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    color: var(--bs-primary);
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 15px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &:hover::before {
      opacity: 1;
    }

    i {
      z-index: 1;
    }

    &.left {
      left: 0;
      &::before {
        background: linear-gradient(to right, rgba(var(--bs-primary-rgb), 0.2), transparent);
      }
    }

    &.right {
      right: 0;
      &::before {
        background: linear-gradient(to left, rgba(var(--bs-primary-rgb), 0.2), transparent);
      }
    }
  }

  .calories-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 2rem;

    // Add this media query for larger screens
    @media (min-width: 768px) {
      padding-left: 60px; // Increase left padding for web version
    }
  }

  .calories-circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: conic-gradient(var(--bs-primary) var(--percentage), #e0e0e0 0deg);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    .calories-inner {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .calories-number {
        font-size: 1.4rem;
        font-weight: bold; 
        color: var(--bs-primary);
        font-family: "Kalam", cursive;
      }

      .calories-unit {
        font-size: 0.7rem;
        color: var(--bs-secondary);
      }
    }
  }

  .calories-info {
    flex-grow: 1;
    padding-left: 1rem;

    h3 {
      font-size: 1.2rem;
      color: var(--bs-body-color);
      margin-bottom: 0.25rem;
    }

    p {
      font-size: 0.9rem;
      color: var(--bs-secondary);
      margin-bottom: 0.1rem;
    }

    .calories-percentage {
      font-size: 1rem;
      font-weight: 600;
      color: var(--bs-primary);
    }
  }

  .calories-navigation-area {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 25%;
    cursor: pointer;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }
}

// Dark mode adjustments
[data-bs-theme="dark"] {
  .total-calories-display {
    background-color: var(--bs-dark);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);

    .calories-navigation {
      &::before {
        background: linear-gradient(to right, rgba(var(--bs-primary-rgb), 0.3), transparent);
      }

      &.right::before {
        background: linear-gradient(to left, rgba(var(--bs-primary-rgb), 0.3), transparent);
      }
    }

    .calories-circle {
      background: conic-gradient(var(--bs-primary) var(--percentage), #444 0deg);

      .calories-inner {
        background: var(--bs-body-bg);
      }
    }

    .calories-info {
      h3 {
        color: var(--bs-light);
      }
    }
  }
}

// Ensure smooth transitions for theme changes
.total-calories-display,
.total-calories-display .calories-circle,
.total-calories-display .calories-inner,
.total-calories-display .calories-navigation {
  transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
}

html {
  overflow-y: scroll;
}

body {
  padding-right: 0 !important;
}

.app {
  max-width: 700px;
  margin: 0 auto;
  padding: 5px;
  padding-bottom: 80px;

  @media (min-width: 768px) {
    padding-bottom: 20px;
  }
}

.container-sm {
  padding-left: 15px;
  padding-right: 15px;
}

.nutrient-summary {
  display: grid;
  gap: 1rem;
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}

.nutrient-item {
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
}

.nutrient-icon {
  font-size: 1.2em;
}

.nutrient-summary.compact {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  .nutrient-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #f8f9fa;  // Light mode background
    border-radius: 8px;
    padding: 0.5rem;
    position: relative;
    overflow: hidden;
    transition: background-color 0.3s ease;  // Add transition for smooth color change

    .nutrient-icon {
      font-size: 1.2rem;
      margin-right: 0.5rem;
    }

    .nutrient-info {
      flex-grow: 1;

      .nutrient-name {
        font-weight: bold;
        font-size: 0.9rem;
      }

      .nutrient-values {
        display: flex;
        justify-content: space-between;
        font-size: 0.8rem;
        color: #6c757d;

        .nutrient-percentage {
          font-weight: bold;
        }
      }
    }

    .nutrient-bar {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 4px;
      background-color: #007bff;
      transition: width 0.3s ease;
    }
  }
}

// Dark mode adjustments
[data-bs-theme="dark"] {
  // ... (keep existing dark mode styles)

  .nutrient-summary.compact {
    .nutrient-item {
      background-color: #2a2a2a;  // Dark mode background
      color: #e0e0e0;  // Light text color for dark mode

      .nutrient-name {
        color: #ffffff;  // White color for nutrient name in dark mode
      }

      .nutrient-values {
        color: #adb5bd;  // Lighter grey for values in dark mode
      }

      .nutrient-bar {
        background-color: #0d6efd;  // Darker background for the nutrient bar in dark mode

        &::after {
          background-color: #0d6efd;  // Keep the same color for the filled part of the bar
        }
      }
    }
  }
}

// Add this new section for the ripple effect
.ripple {
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: width 0.3s ease-out, height 0.3s ease-out, opacity 0.3s ease-out;
  }

  &.ripple-active::after {
    width: 200%;
    height: 200%;
    opacity: 1;
  }
}

.total-calories-display {
  // ... existing styles ...

  .calories-navigation {
    // ... existing styles ...
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      background-color: rgba(var(--bs-primary-rgb), 0.1);
      color: var(--bs-primary);
    }

    &:active {
      background-color: rgba(var(--bs-primary-rgb), 0.2);
    }
  }
}

// Dark mode adjustments
[data-bs-theme="dark"] {
  // ... existing dark mode styles ...

  .total-calories-display {
    .calories-navigation {
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      &:active {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  .ripple::after {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

// Add this new section for the nutrition tabs
.nutrition-tabs {
  padding-top: 20px; // Add 20px padding above the buttons
  margin-bottom: 0; // Remove bottom margin

  .nav-tabs {
    border-bottom: 1px solid var(--bs-border-color);

    .nav-item {
      margin-bottom: -1px; // Align bottom of tabs with border

      .nav-link {
        border: 1px solid transparent;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        padding: 0.5rem 1rem;
        font-weight: 500;
        color: var(--bs-body-color);
        background-color: transparent;
        transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;

        &:hover {
          background-color: rgba(var(--bs-primary-rgb), 0.05);
          border-color: var(--bs-border-color) var(--bs-border-color) transparent;
        }

        &.active {
          color: var(--bs-primary);
          background-color: var(--bs-body-bg);
          border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
        }
      }
    }
  }
}

// Update the total-calories-display styles
.total-calories-display {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
  // ... (keep other existing styles)
}

// Dark mode adjustments
[data-bs-theme="dark"] {
  .nutrition-tabs {
    .nav-tabs {
      border-bottom-color: var(--bs-border-color-translucent);

      .nav-item {
        .nav-link {
          color: var(--bs-body-color);

          &:hover {
            background-color: rgba(255, 255, 255, 0.05);
            border-color: var(--bs-border-color-translucent) var(--bs-border-color-translucent) transparent;
          }

          &.active {
            color: var(--bs-primary);
            background-color: var(--bs-body-bg);
            border-color: var(--bs-border-color-translucent) var(--bs-border-color-translucent) var(--bs-body-bg);
          }
        }
      }
    }
  }
}

// Ensure the nutrition dashboard doesn't have extra padding
.nutrition-dashboard {
  padding-top: 0;
}

.nutrition-dashboard {
  padding-top: 20px; // Add padding to the top of the dashboard
}

.nutrition-tabs {
  margin-bottom: -15px;
  padding-left: 20px; // Move tabs 20px to the right

  .nav-tabs {
    border-bottom: none;

    .nav-item {
      margin-bottom: 0;

      .nav-link {
        border: none;
        padding: 0.75rem 1.5rem;
        font-weight: 500;
        color: var(--bs-body-color);
        background-color: transparent;
        transition: color 0.2s ease, background-color 0.2s ease, box-shadow 0.2s ease;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;

        &:hover {
          background-color: rgba(var(--bs-primary-rgb), 0.1);
        }

        &.active {
          color: var(--bs-primary);
          background-color: var(--bs-body-bg);
          box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

.total-calories-display {
  margin-top: 10;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding-top: 10px; // Add 10px padding to the top of the card
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
}

// Dark mode adjustments
[data-bs-theme="dark"] {
  .nutrition-tabs {
    .nav-tabs {
      .nav-item {
        .nav-link {
          color: var(--bs-body-color);

          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }

          &.active {
            color: var(--bs-primary);
            background-color: var(--bs-body-bg);
            box-shadow: 0 -4px 10px rgba(255, 255, 255, 0.1);
          }
        }
      }
    }
  }

  .total-calories-display {
    box-shadow: 0 -4px 10px rgba(255, 255, 255, 0.1);
  }
}

.nutrition-dashboard {
  border: 1px solid var(--bs-border-color); // this is light mode the Nutrition summary boarder
  border-radius: 8px;
  overflow: hidden;
}

.nutrition-dashboard.collapsed .total-calories-display {
  padding: 5px;
}

.total-calories-display {
  cursor: pointer;
  transition: padding 0.3s ease;
}

.nutrition-dashboard.expanded .total-calories-display {
  padding: 15px;
}

.nutrient-summary {
  padding: 15px;
}

.upload-drawer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 90vh;
  background-color: var(--bs-body-bg);
  transform: translateY(100%);
  transition: transform 0.3s ease-out;
  z-index: 1050;
  overflow-y: auto;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  &.open {
    transform: translateY(0);
  }

  .drawer-content {
    padding: 20px;
    padding-bottom: 80px; // Add extra padding at the bottom to account for the fixed button
  }

  .close-drawer {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: var(--bs-body-color);
    cursor: pointer;
  }
}

// Adjust the main content area when the drawer is open
.app {
  transition: transform 0.3s ease-out;

  &.drawer-open {
    transform: translateY(-90vh);
  }
}

// Hide the upload tab on mobile
@media (max-width: 767px) {
  .nav-tabs .nav-item[data-tab="upload"] {
    display: none;
  }
}

// Ensure the fixed bottom button is above the drawer
.fixed-bottom {
  z-index: 1060;
}

// Dark mode adjustments
[data-bs-theme="dark"] {
  .upload-drawer {
    background-color: var(--bs-dark);
    box-shadow: 0 -2px 10px rgba(255, 255, 255, 0.1);
  }
}

@media (max-width: 767px) {
  .nav-tabs .nav-item[data-tab="upload"] {
    display: none;
  }
}

@media (min-width: 768px) {
  .fixed-bottom {
    display: none;
  }
}

.saved-recipes {
  .recipe-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
  }

  .recipe-card {
    background-color: var(--bs-light);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }

    .recipe-image {
      height: 200px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .placeholder-image {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f0f0f0;
        color: #999;
        font-size: 3rem;
      }
    }

    .recipe-content {
      padding: 1rem;
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .recipe-title {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
        font-weight: bold;
        line-height: 1.2;
        height: 2.4em; // Fixed height for 2 lines
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }

      .recipe-details {
        flex-grow: 1;
      }

      .recipe-cooking-time {
        font-size: 0.9rem;
        color: var(--bs-secondary);
      }
    }

    .recipe-actions {
      padding: 0.5rem 1rem;
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;
      border-top: 1px solid var(--bs-border-color);
    }
  }
}

.recipe-modal-overlay {
  position: fixed;
  top: 0;
  left: 10px;
  right: 10px;
  bottom: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.recipe-modal-content {
  background-color: var(--bs-light);
  border-radius: 10px;
  padding: 1rem;
  max-width: 900px;
  max-height: 90%;
  overflow-y: auto;
  position: relative;

  .close-button {
    position: fixed;  // Change from absolute to fixed
    top: 1rem;       // Adjust from 0rem to 1rem for better spacing
    right: calc(50% - 450px + 1rem);  // Center relative to modal width (900px/2) plus padding
    background-color: var(--bs-light);  // Add background color
    border-radius: 50%;                 // Make it circular
    width: 36px;                        // Set fixed width
    height: 36px;                       // Set fixed height
    display: flex;                      // Center the icon
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);  // Add shadow for better visibility
    z-index: 1100;                      // Ensure it stays above content
    transition: background-color 0.3s ease;  // Smooth transition for hover effects

    &:hover {
      background-color: var(--bs-danger);
      color: white;
    }
  }

  .recipe-image-container {
    width: 100%;
    max-height: 50vh;
    overflow: hidden;
    margin-bottom: 1rem;
    border-radius: 10px;
    position: relative;
    background-color: #f0f0f0;

    @media (min-width: 768px) {
      max-height: 60vh;
    }

    .recipe-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0;
      transition: opacity 0.3s ease;

      &.loaded {
        opacity: 1;
      }
    }

    .image-placeholder {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 3rem;
      color: #999;
    }
  }

  h2 {
    margin-bottom: 1rem;
  }

  h3 {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .modal-actions {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
}

// Add responsive adjustments for mobile
@media (max-width: 900px) {
  .recipe-modal-content {
    .close-button {
      right: 1rem;  // On smaller screens, just stick to the right edge
    }
  }
}

// Dark mode adjustments
[data-bs-theme="dark"] {
  .recipe-modal-content {
    .close-button {
      background-color: var(--bs-dark);
      color: var(--bs-light);

      &:hover {
        background-color: var(--bs-danger);
      }
    }
  }
}

.search-bar {
  margin-bottom: 1.5rem;
  position: relative;

  input {
    width: 100%;
    padding: 0.75rem 1rem 0.75rem 2.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 10px;
    background-color: var(--bs-body-bg);
    color: var(--bs-body-color);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, background-color 0.3s ease;

    &:focus {
      outline: none;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    }

    &::placeholder {
      color: var(--bs-secondary);
    }
  }

  .search-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--bs-secondary);
    pointer-events: none;
  }
}

// Dark mode adjustments
[data-bs-theme="dark"] {
  .recipe-card {
    background-color: var(--bs-dark);

    .recipe-image .placeholder-image {
      background-color: #2a2a2a;
      color: #666;
    }
  }

  .recipe-modal-content {
    background-color: var(--bs-dark);
  }

  .search-bar input {
    background-color: var(--bs-dark);
    color: var(--bs-light);
    border-color: var(--bs-border-color-translucent);

    &:focus {
      border-color: var(--bs-primary);
    }
  }
}

// Dark mode adjustments
[data-bs-theme="dark"] {
  .saved-recipes {
    .recipe-card {
      background-color: #2a2a2a;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

      &:hover {
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
      }

      .recipe-image .placeholder-image {
        background-color: #1e1e1e;
        color: #555;
      }

      .recipe-content {
        .recipe-title {
          color: #e0e0e0;
        }

        .recipe-cooking-time {
          color: #adb5bd;
        }
      }

      .recipe-actions {
        .btn-outline-danger {
          color: #ff6b6b;
          border-color: #ff6b6b;

          &:hover {
            background-color: #ff6b6b;
            color: #1a1a1a;
          }
        }

        .btn-outline-primary {
          color: #4dabf7;
          border-color: #4dabf7;

          &:hover {
            background-color: #4dabf7;
            color: #1a1a1a;
          }
        }
      }
    }
  }

  .recipe-modal-overlay {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .recipe-modal-content {
    background-color: #2a2a2a;
    color: #e0e0e0;

    .close-button {
      color: #e0e0e0;
    }

    h2, h3 {
      color: #4dabf7;
    }

    .modal-actions {
      .btn-danger {
        background-color: #dc3545;
        border-color: #dc3545;
      }

      .btn-primary {
        background-color: #0d6efd;
        border-color: #0d6efd;
      }
    }
  }

  .search-bar input {
    background-color: #1e1e1e;
    color: #e0e0e0;
    border-color: #444;

    &:focus {
      border-color: #4dabf7;
    }

    &::placeholder {
      color: #adb5bd;
    }
  }
}

// Ensure smooth transitions for theme changes
.recipe-card,
.recipe-modal-content,
.search-bar input {
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

//testing deployment

.user-profile-container {
  background: var(--bs-body-bg);
  border-radius: 16px;
  padding: 1.5rem;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);

  .profile-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--bs-body-color);
    margin: 0;
  }

  .profile-actions {
    .logout-button {
      background: transparent;
      border: 2px solid var(--bs-danger);
      color: var(--bs-danger);
      font-weight: 500;
      padding: 0.75rem;
      transition: all 0.2s ease;

      &:hover {
        background: var(--bs-danger);
        color: white;
        transform: translateY(-1px);
        box-shadow: 0 4px 12px rgba(var(--bs-danger-rgb), 0.15);
      }

      &:active {
        transform: translateY(0);
        box-shadow: none;
      }
    }  
  }
    .theme-toggle {
    position: relative;
    
    &-input {
      opacity: 0;
      position: absolute;
      
      &:checked + .theme-toggle-label {
        background: #365c7d;
        
        .toggle-ball {
          transform: translateX(24px);
          background: #1e3a5f;
        }
        
        .sun-icon {
          opacity: 0;
        }
        
        .moon-icon {
          opacity: 1;
        }
      }
    }
    
    &-label {
      width: 50px;
      height: 26px;
      background: #83d8ff;
      border-radius: 50px;
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 5px;
      transition: background 0.3s ease;
      
      .sun-icon,
      .moon-icon {
        font-size: 14px;
        color: white;
        transition: opacity 0.3s ease;
      }
      
      .sun-icon {
        opacity: 1;
      }
      
      .moon-icon {
        opacity: 0;
      }
      
      .toggle-ball {
        position: absolute;
        width: 22px;
        height: 22px;
        background: #fff;
        border-radius: 50%;
        left: 2px;
        transition: transform 0.3s ease, background 0.3s ease;
      }
    }
  }

  .profile-stats {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
    margin-top: 1.5rem;

    .stat-card {
      background: var(--bs-body-bg);
      border: 1px solid var(--bs-border-color);
      border-radius: 12px;
      padding: 1rem;
      display: flex;
      align-items: center;
      transition: transform 0.2s ease, box-shadow 0.2s ease;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      }

      .stat-icon {
        width: 40px;
        height: 40px;
        background: var(--bs-primary-bg-subtle);
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;

        i {
          font-size: 1.2rem;
          color: var(--bs-primary);
        }
      }

      .stat-content {
        flex: 1;

        .stat-label {
          display: block;
          font-size: 0.875rem;
          color: var(--bs-secondary);
          margin-bottom: 0.25rem;
        }

        .stat-value {
          display: block;
          font-size: 1rem;
          font-weight: 600;
          color: var(--bs-body-color);
        }
      }
    }
  }

  .profile-header {
    padding-bottom: 1.5rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid var(--bs-border-color);

    .profile-title {
      font-size: 1.5rem;
      font-weight: 600;
      color: var(--bs-body-color);
      margin: 0;
      display: flex;
      align-items: center;

      i {
        color: var(--bs-primary);
      }
    }

    .btn-outline-danger {
      padding: 0.5rem 1rem;
      border-width: 2px;
      transition: all 0.2s ease;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      i {
        font-size: 1.1rem;
      }

      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 2px 5px rgba(var(--bs-danger-rgb), 0.3);
      }

      &:active {
        transform: translateY(0);
      }
    }

    .theme-toggle {
      margin-right: 1rem;
    }
  }
}

// Dark mode adjustments
[data-bs-theme="dark"] {
  .user-profile-container {
    background: var(--bs-dark);
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);

    .stat-card {
      background: var(--bs-dark);
      border-color: var(--bs-border-color-translucent);

      &:hover {
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
      }

      .stat-icon {
        background: rgba(var(--bs-primary-rgb), 0.15);
      }
    }
  }
}

.nutrition-dashboard .loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1;
  border-radius: inherit;
}

.nutrition-dashboard .total-calories-display {
  position: relative;
}

/* Add to your existing CSS */
.loading-skeleton {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  animation: pulse 1.5s ease-in-out infinite;
}

.skeleton-circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #e0e0e0;
}

.skeleton-text {
  flex: 1;
}

.skeleton-line {
  height: 12px;
  background: #e0e0e0;
  border-radius: 4px;
  margin: 8px 0;
}

.skeleton-line:first-child {
  width: 60%;
}

.skeleton-line:last-child {
  width: 40%;
}

.nutrient-item.skeleton {
  display: flex;
  gap: 1rem;
  padding: 0.5rem;
  animation: pulse 1.5s ease-in-out infinite;
}

.skeleton-icon {
  width: 24px;
  height: 24px;
  background: #e0e0e0;
  border-radius: 50%;
}

.skeleton-content {
  flex: 1;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.total-calories-display.loading {
  pointer-events: none;
  opacity: 0.8;
}

.total-calories-display.loading .calories-navigation {
  opacity: 0.5;
}

// Add this to the end of the file
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bs-body-bg);
  z-index: 9999;
}

.spinner-container {
  text-align: center;
  
  .spinner-border {
    width: 3rem;
    height: 3rem;
  }
  
  p {
    font-size: 1.2rem;
    margin-top: 1rem;
    color: var(--bs-primary);
  }
}

// Add a toast for error messages
.error-toast {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: var(--bs-danger);
  color: white;
  padding: 15px 20px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10000;
  max-width: 350px;
}
