.recipe-content {
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-height: 400px;
    overflow-y: auto;
    transition: max-height 0.3s ease-out, padding 0.3s ease-out;
  }
  
  .recipe-content.collapsed {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
  }
  
  .recipe-section {
    transition: opacity 0.3s ease-out;
  }
  
  .recipe-section.collapsed {
    opacity: 0;
  }

.meal-category {
  font-weight: 600;
  text-transform: uppercase;
  color: #495057;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  border-left: 4px solid;
}

.meal-category.breakfast {
  border-color: #ffc107;
}

.meal-category.lunch {
  border-color: #28a745;
}

.meal-category.dinner {
  border-color: #007bff;
}

.meal-category.snack {
  border-color: #6c757d;
}

.consumption-history-item {
  border-left: 4px solid transparent;
  transition: border-color 0.3s ease;
}

.consumption-history-item:hover {
  border-left-color: #007bff;
}

.card-header {
  background-color: #f8f9fa;
  border-bottom: none;
}

.item-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
}

.item-info {
  flex-grow: 1;
  padding: 0 1rem;
}

.item-title {
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.item-meta {
  font-size: 0.875rem;
  color: #6c757d;
}

.item-expand {
  font-size: 1.25rem;
  color: #6c757d;
}
