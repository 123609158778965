.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background: var(--bs-body-bg);

  .auth-form {
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    background: var(--bs-body-bg);
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    h1 {
      text-align: center;
      margin-bottom: 2rem;
      color: var(--bs-body-color);
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    input {
      padding: 0.75rem;
      border: 1px solid var(--bs-border-color);
      border-radius: 4px;
      background: var(--bs-body-bg);
      color: var(--bs-body-color);

      &:focus {
        outline: none;
        border-color: var(--bs-primary);
      }
    }

    button {
      padding: 0.75rem;
      border: none;
      border-radius: 4px;
      background: var(--bs-primary);
      color: white;
      cursor: pointer;
      font-weight: 600;

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }

      &:not(:disabled):hover {
        background: var(--bs-primary-dark);
      }
    }

    .auth-message {
      padding: 1rem;
      margin-bottom: 1rem;
      border-radius: 4px;
      background: var(--bs-warning-bg-subtle);
      color: var(--bs-warning-text);
      text-align: center;
    }

    .auth-divider {
      display: flex;
      align-items: center;
      margin: 1.5rem 0;
      color: var(--bs-secondary);

      &::before,
      &::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid var(--bs-border-color);
      }

      span {
        padding: 0 1rem;
      }
    }

    .magic-link-button {
      width: 100%;
      background: var(--bs-secondary);
    }

    .auth-switch {
      margin-top: 1.5rem;
      text-align: center;
      color: var(--bs-body-color);

      button {
        background: none;
        border: none;
        color: var(--bs-primary);
        padding: 0;
        margin-left: 0.5rem;
        font-weight: 600;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
} 